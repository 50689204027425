export const CONTENT_TYPES = {
  PAGE: 'page',
  PRESS_STORY: 'pressStory',
  ARTICLE: 'article',
  CORPORATE_BLOG: 'corporateBlog',
  CONSUMER_BLOG: 'consumerBlog',
  PERMALINK_MEDIA: 'permalinkMedia',
  FFAM_BANNER: 'ffamBanner',
  HEADER: 'header',
  FOOTER: 'footer',
  EMERGENCIES_BANNER: 'emergenciesBanner',
  EXTERNAL_LINK: 'externalLink',
  JSON_FIELD: 'jsonField',
}

export const LANGUAGES = {
  DEFAULT: 'en-US',
}

export const PAGE_LIMIT_DEFAULT = 9

export const NESTED_REFERENCE_RESOLUTION_DEPTH = 4
